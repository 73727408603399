import heroImg from "../content/la.jpg";


const Hero = () => {
    return (
        <>
            <section className="hero">
                <h1>
                    Los Angeles
                </h1>
                <p>
                    we had the best time playing at venice beach
                </p>
            </section>
        </>
    )
}

export default Hero;