//icons
import sechIcon from "../content/Search-Icon.png";



const Nav = () => {
    return (
    <>
        <nav>
            <a>
            HOME
            </a>
            <a>
            BAND
            </a>
            <a>
            TOUR
            </a>
            <a>
            CONTACT
            </a>
            <a>
            MORE
            </a>
            <img src={sechIcon}/>
        </nav>
    </>
    )
}

export default Nav;